import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { transactionActionTypes } from './constants';
import { ITransactions } from '@common/types/transaction.types';

interface TransactionState {
  isLoading: boolean;
  error: null | any;
  transactionInfo: ITransactions | null;
}

const initialState: TransactionState = {
  isLoading: false,
  error: null,
  transactionInfo: null,
};

// @ts-ignore
const transactionReducer: Reducer<Map<string, any>, transactionActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case transactionActionTypes.GET_TRANSACTION_INFO:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case transactionActionTypes.GET_TRANSACTION_INFO_SUCCESS:
      return state.merge({
        isLoading: false,
        transactionInfo: payload,
      });

    case transactionActionTypes.GET_TRANSACTION_INFO_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case transactionActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });

    default:
      return state;
  }
};

export default transactionReducer;
