import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { imageActionTypes } from './constants';

interface ImageState {
  isLoading: boolean;
  error: null | any;
}

const initialState: ImageState = {
  isLoading: false,
  error: null,
};

// @ts-ignore
const imageReducer: Reducer<Map<string, any>, imageActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case imageActionTypes.RESET_ALL:
      return fromJS(initialState);

    default:
      return state;
  }
};

export default imageReducer;
