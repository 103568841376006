export interface ITimeCacheRecord<T = any> {
  data: T;
  timestamp: number;
  lifetime?: number;
}

export const TIME_CACHE_DEFAULT_LIFETIME_MS =
  5 /* min */ * 60 /* sec */ * 1000; /* ms */

export class TimeCache<T = any> {
  private cache: Record<string, ITimeCacheRecord<T>> = {};

  private enabled: boolean = true;

  public readCache(key: string) {
    if (!this.enabled) {
      return null;
    }

    const record = this.cache[key];

    // eslint-disable-next-line eqeqeq
    if (record == null) {
      return null;
    }

    const timestampNow = new Date().getTime();
    const timestampDiff = timestampNow - record.timestamp;
    const lifetime = record.lifetime ?? TIME_CACHE_DEFAULT_LIFETIME_MS;

    if (timestampDiff > lifetime) {
      return null;
    }

    return record;
  }

  public writeCache(key: string, data: T, lifetime?: number) {
    if (!this.enabled) {
      return;
    }

    const newRecord: ITimeCacheRecord<T> = {
      data,
      lifetime,
      timestamp: new Date().getTime(),
    };

    this.cache[key] = newRecord;
  }

  public resetCache(key: string) {
    delete this.cache[key];
  }

  public async resetAll() {
    this.cache = {};
  }

  public enable() {
    this.enabled = true;
  }

  public disable() {
    this.enabled = false;
  }
}
