import styled, { css } from 'styled-components/macro';
import { ToastContainer as OriginalToastContainer } from 'react-toastify';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  transition: min-height 0.3s ease;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
`;

export const StyledToastContainer = styled(OriginalToastContainer)`
  .Toastify__toast {
    background-color: #544ea7;
    color: white;
  }
  .Toastify__progress-bar--error,
  .Toastify__progress-bar--animated {
    background: #766ee7;
  }
  .Toastify__toast-body {
    font-size: 14px;
  }
`;

export const FontSize24 = css`
  font-size: 26px;
  color: white;
  z-index: 1;
  font-weight: 700;
  @media (min-width: 1281px) {
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    /* CSS */
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    /* CSS */
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* CSS */
  }

  @media (min-width: 481px) and (max-width: 767px) {
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 24px;
  }
`;

// const FontSize24 = styled.p`
//   font-size: 26px;
//   color: white;
//   z-index: 1;
//   font-weight: 700;
//
//   @media (min-width: 320px) and (max-width: 480px) {
//     font-size: 30px;
//   }
// `;
//
// const TitleWrapper = styled.div`
//   ${FontSize24};
//   color: red;
//
//   @media (min-width: 320px) and (max-width: 480px) {
//     ${FontSize24} {
//       font-size: 24px;
//     }
//   }
// `;
