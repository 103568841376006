import * as React from 'react';

function ConnectionLost(props: any) {
  return (
    <svg
      width="45"
      height="35"
      viewBox="0 0 45 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3161 15.63C6.37803 16.568 6.37803 18.0947 7.3161 19.042C8.25418 19.9801 9.78085 19.9801 10.7281 19.042C17.2211 12.549 27.7882 12.549 34.2812 19.042C34.7502 19.511 35.3664 19.7501 35.9826 19.7501C36.5988 19.7501 37.2149 19.511 37.684 19.042C38.6221 18.1039 38.6221 16.5772 37.684 15.63C29.3149 7.25168 15.6852 7.25168 7.3161 15.63Z"
        fill="white"
      ></path>
      <path
        d="M13.8276 22.1413C12.8895 23.0794 12.8895 24.6061 13.8276 25.5533C14.7657 26.4914 16.2923 26.4914 17.2396 25.5533C20.1366 22.6472 24.8637 22.6472 27.7607 25.5533C28.2298 26.0224 28.846 26.2615 29.4621 26.2615C30.0783 26.2615 30.6945 26.0224 31.1636 25.5533C32.1016 24.6153 32.1016 23.0886 31.1636 22.1505C26.3904 17.359 18.6099 17.359 13.8276 22.1413Z"
        fill="white"
      ></path>
      <path
        d="M22.5 34.0144C24.2726 34.0144 25.7097 32.5774 25.7097 30.8047C25.7097 29.0321 24.2726 27.595 22.5 27.595C20.7273 27.595 19.2903 29.0321 19.2903 30.8047C19.2903 32.5774 20.7273 34.0144 22.5 34.0144Z"
        fill="white"
      ></path>
      <path
        d="M44.2964 9.00828C32.2762 -3.00276 12.7238 -3.00276 0.703556 9.00828C-0.234519 9.94635 -0.234519 11.473 0.703556 12.4203C1.64163 13.3584 3.1683 13.3584 4.11557 12.4203C14.2505 2.27621 30.7495 2.27621 40.8936 12.4203C41.3627 12.8893 41.9788 13.1284 42.595 13.1284C43.2112 13.1284 43.8274 12.8893 44.2964 12.4203C45.2345 11.4822 45.2345 9.95555 44.2964 9.00828Z"
        fill="white"
      ></path>
    </svg>
  );
}
export default ConnectionLost;
