import { Dispatch } from 'redux';
import { authActionTypes } from './constants';
import {
  IForgotPassword,
  IRegisterRequestModel,
  ISignInRequestModel,
  IResetPassword,
  ITelegramLoginRequestModel,
} from '@common/types/auth.types';
import {
  forgotPassword,
  registerUser,
  signIn,
  refreshTokenReq,
  resetPassword,
} from '@api/auth';
import { toast } from 'react-toastify';
import { showErrorToast } from '@constants/request.constants';
import authStateService from '@services/authStateService';
import http from '@api/http';

import omit from 'lodash/omit';
import get from 'lodash/get';
import {
  discordNotification,
  GameErrorType,
} from '@services/discordNotification';

const authState = authStateService();

export const authActions = {
  // SIGN IN
  signInAction: () => ({
    type: authActionTypes.SIGN_IN,
  }),
  signInSuccessAction: (payload: any) => ({
    type: authActionTypes.SIGN_IN_SUCCESS,
    payload,
  }),
  signInFailAction: (payload: any) => ({
    type: authActionTypes.SIGN_IN_FAIL,
    payload,
  }),

  // SIGN OUT
  signOutAction: () => ({
    type: authActionTypes.SIGN_OUT,
  }),
  signOutSuccessAction: () => ({
    type: authActionTypes.SIGN_OUT_SUCCESS,
  }),
  signOutFailAction: () => ({
    type: authActionTypes.SIGN_OUT_FAIL,
  }),

  // SIGN UP
  signUpAction: () => ({
    type: authActionTypes.SIGN_UP,
  }),
  signUpSuccessAction: (payload: any) => ({
    type: authActionTypes.SIGN_UP_SUCCESS,
    payload,
  }),
  signUpFailAction: (payload: any) => ({
    type: authActionTypes.SIGN_UP_FAIL,
    payload,
  }),

  // FORGOT_PASSWORD
  forgotPasswordAction: () => ({
    type: authActionTypes.POST_FORGOT_PASSWORD,
  }),
  forgotPasswordSuccessAction: () => ({
    type: authActionTypes.POST_FORGOT_PASSWORD_SUCCESS,
  }),
  forgotPasswordFailAction: (payload: any) => ({
    type: authActionTypes.POST_FORGOT_PASSWORD_FAIL,
    payload,
  }),

  // CHECK TOKEN
  checkTokenSuccessAction: (payload: any) => ({
    type: authActionTypes.CHECK_TOKEN_SUCCESS,
    payload,
  }),
  checkTokenFailAction: () => ({
    type: authActionTypes.CHECK_TOKEN_FAIL,
  }),

  // RESET_PASSWORD
  resetPasswordAction: () => ({
    type: authActionTypes.POST_RESET_PASSWORD,
  }),
  resetPasswordSuccessAction: () => ({
    type: authActionTypes.POST_RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordFailAction: (payload: any) => ({
    type: authActionTypes.POST_RESET_PASSWORD_FAIL,
    payload,
  }),

  // CLEAR ERROR
  clearErrorMessageAction: () => ({
    type: authActionTypes.CLEAR_ERROR,
  }),
};

export const postLoginUser =
  (params: ISignInRequestModel) => async (dispatch: Dispatch) => {
    try {
      dispatch(authActions.signInAction());
      const response = await signIn(omit(params, ['intl']));

      await authState.setToken(response.data);
      dispatch(
        authActions.signInSuccessAction({
          isAuthorized: Boolean(response.success),
        })
      );
      return response.data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, 'response.data.message', '');

      // Отправляем уведомление только если это НЕ ошибка неверных учетных данных
      if (
        !errorResponse.includes('Invalid credentials') &&
        !errorResponse.includes('User not found') &&
        !errorResponse.includes('Invalid password')
      ) {
        discordNotification.sendErrorNotification({
          errorType: GameErrorType.EMAIL_AUTH_ERROR,
          additionalInfo: `Authentication error: ${
            errorResponse || 'Unknown error'
          }`,
          userId: params.username,
        });
      }

      showErrorToast(errorResponse, params.intl);
      dispatch(authActions.signInFailAction({ error }));
    }
  };

export const postLoginTelegram =
  (params: ITelegramLoginRequestModel) => async (dispatch: Dispatch) => {
    try {
      dispatch(authActions.signInAction());

      // Використання token та refreshToken для авторизації
      // Я припускаю, що ваша функція signIn може обробити ці параметри, але це може вимагати додаткової модифікації.

      await authState.setToken(params);
      dispatch(authActions.signInSuccessAction({ isAuthorized: true }));
    } catch (error) {
      // Отправляем уведомление об ошибке в Discord
      discordNotification.sendErrorNotification({
        errorType: GameErrorType.TELEGRAM_AUTH_ERROR,
        additionalInfo:
          error?.toString() || 'Unknown Telegram authentication error',
        userId: params.id, // Telegram user ID если есть
      });

      dispatch(authActions.signInFailAction({ error: 'Telegram auth Fail' }));
      console.log(error);
    }
  };

export const postRegisterUser =
  (params: IRegisterRequestModel) => async (dispatch: Dispatch) => {
    try {
      dispatch(authActions.signUpAction());
      const response = await registerUser(omit(params, ['intl']));

      await authState.setToken(response.data);
      dispatch(
        authActions.signUpSuccessAction({
          isAuthorized: Boolean(response.success),
        })
      );
      return response.data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, 'response.data.errors', '');

      showErrorToast(errorResponse[0], params.intl);
      dispatch(authActions.signUpFailAction({ error }));
    }
  };

export const postForgotPassword =
  (params: IForgotPassword) => async (dispatch: Dispatch) => {
    try {
      dispatch(authActions.forgotPasswordAction());
      const response = await forgotPassword(omit(params, ['intl']));

      dispatch(authActions.forgotPasswordSuccessAction());
      toast.success(
        'Password reset instruction sent to your e-mail. If you have not received an email, please check in your spam box'
      );

      return response.data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, 'response.data.message', '');

      if (errorResponse) {
        toast.error(errorResponse);
      }
      // showErrorToast(errorResponse, params.intl)
      dispatch(authActions.forgotPasswordFailAction({ error }));
    }
  };

export const refreshToken = (params: any) => async (dispatch: Dispatch) => {
  const refreshTokenFromStorage = await authState.getRefreshToken();

  if (!refreshTokenFromStorage) {
    return Promise.reject(new Error('No refresh token found'));
  }

  try {
    const send = http(dispatch, true);

    const response = await refreshTokenReq({
      refresh_token: refreshTokenFromStorage,
    });

    await authState.setToken(response.data);

    return response;
  } catch (error) {
    // @ts-ignore
    if (error.message === 'Network Error' && !error.response) {
      return Promise.reject(error);
    }
    console.error('Refreshing token try failed', error);

    await authState.clearToken();
    dispatch(authActions.signOutSuccessAction());

    return Promise.reject(error);
  }
};

export const handleCheckToken = () => async (dispatch: Dispatch) => {
  try {
    const authState = authStateService();
    const token = await authState.getAccessToken();

    dispatch(
      authActions.checkTokenSuccessAction({ isAuthorized: Boolean(token) })
    );
  } catch (err) {
    dispatch(authActions.checkTokenFailAction());
  }
};

export const handleLogoutAction = () => async (dispatch: Dispatch) => {
  try {
    await authState.clearToken();
    dispatch(authActions.signOutSuccessAction());
  } catch (err) {
    dispatch(authActions.signOutFailAction());
  }
};

export const postResetPassword =
  (params: IResetPassword) => async (dispatch: Dispatch) => {
    try {
      dispatch(authActions.resetPasswordAction());
      const response = await resetPassword(omit(params, ['intl']));

      await authState.setToken(response.data);
      dispatch(authActions.resetPasswordSuccessAction());
      toast.success('Password changed');
      return response.data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, 'response.data.message', '');

      if (errorResponse) {
        toast.error(errorResponse);
      }
      dispatch(authActions.resetPasswordFailAction({ error }));
    }
  };

export const resetAll = () => ({
  type: authActionTypes.RESET_ALL,
});
