import { createSelector } from 'reselect';

const selectMain = (state: any) => state.get('auth');

export const selectIsLoading = createSelector(selectMain, (substate) =>
  substate.get('isLoading')
);
export const selectIsAuthorized = createSelector(selectMain, (substate) =>
  substate.get('isAuthorized')
);
export const selectIsCheckingAuthorization = createSelector(
  selectMain,
  (substate) => substate.get('isCheckingAuthorization')
);

export const selectIsBanned = (state: any) =>
  state.get('auth')?.get('isBanned');
