import styled from 'styled-components/macro';
import pattern from '@assets/icons/svg/pattern.svg';

export const MaintenanceContainer = styled.div`
  width: 100%;
  max-width: 414px;
  height: 100vh;
  max-height: 896px;
  margin: 0 auto;
  background-image: url(${pattern}),
    linear-gradient(180deg, #352e7e 0%, #6053e4 100%);
  background-position: top center;
  background-repeat: repeat, no-repeat;
  background-size: auto, 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;

export const MaintenanceIcon = styled.div`
  width: 200px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const MaintenanceText = styled.div`
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
