import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ visible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: opacity 0.3s linear;
`;

interface Props {
  visible: boolean;
}

const LoaderWrapper: React.FC<Props & PropsWithChildren> = ({
  visible,
  children,
}) => <Wrapper visible={visible}>{children}</Wrapper>;

export default LoaderWrapper;
