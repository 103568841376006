import axiosInstance from '../configAPI';

import {
  IForgotPassword,
  IRefreshTokenRequest,
  IRegisterRequestModel,
  ISignInRequestModel,
  IResetPassword,
} from '@common/types/auth.types';

export const signIn = async (params: ISignInRequestModel) =>
  axiosInstance({
    url: '/login_check',
    method: 'POST',
    data: params,
  });

export const registerUser = async (params: IRegisterRequestModel) =>
  axiosInstance({
    url: '/register',
    method: 'POST',
    data: { ...params, agreeTerms: true },
  });

export const forgotPassword = async (params: IForgotPassword) =>
  axiosInstance({
    url: '/reset-password',
    method: 'POST',
    data: params,
  });

export const refreshTokenReq = async (params: IRefreshTokenRequest) =>
  axiosInstance({
    url: '/token/refresh',
    method: 'POST',
    data: params,
  });

export const resetPassword = async (params: IResetPassword) =>
  axiosInstance({
    url: `/reset/${params.token}`,
    method: 'POST',
    data: {
      plainPassword: {
        first: params.first,
        second: params.second,
      },
    },
  });
