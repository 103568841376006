export const depositActionTypes = {
  RESET_ALL: 'RESET_ALL',

  POST_DEPOSIT: 'POST_DEPOSIT',
  POST_DEPOSIT_SUCCESS: 'POST_DEPOSIT_SUCCESS',
  POST_DEPOSIT_FAIL: 'POST_DEPOSIT_FAIL',

  SET_CURRENT_AMOUNT: 'SET_CURRENT_AMOUNT',
  SET_SELECTED_TOKEN: 'SET_SELECTED_TOKEN',
  SET_IS_INITIAL_SELECTED_TOKEN: 'SET_IS_INITIAL_SELECTED_TOKEN',

  CLEAR_ERROR: 'CLEAR_ERROR',
};
