import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { depositActionTypes } from './constants';

interface depositState {
  isLoading: boolean;
  isInitialSelectedToken: boolean;
  error: null | any;
  depositInfo: any;
  currentAmount: any;
  selectedToken: any;
}

const initialState: depositState = {
  isLoading: false,
  isInitialSelectedToken: true,
  error: null,
  depositInfo: null,
  currentAmount: null,
  selectedToken: null,
};

// @ts-ignore
const depositReducer: Reducer<Map<string, any>, depositActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case depositActionTypes.POST_DEPOSIT:
      return state.merge({
        isLoading: true,
      });

    case depositActionTypes.POST_DEPOSIT_SUCCESS:
      return state.merge({
        isLoading: false,
        depositInfo: payload,
      });

    case depositActionTypes.POST_DEPOSIT_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case depositActionTypes.SET_CURRENT_AMOUNT:
      return state.merge({
        currentAmount: payload,
      });

    case depositActionTypes.SET_SELECTED_TOKEN:
      return state.merge({
        selectedToken: payload,
      });

    case depositActionTypes.SET_IS_INITIAL_SELECTED_TOKEN:
      return state.merge({
        isInitialSelectedToken: payload,
      });

    case depositActionTypes.RESET_ALL:
      return fromJS(initialState);

    default:
      return state;
  }
};

export default depositReducer;
