import { useCallback, useEffect, useState } from 'react';

const getImagePromise = (imageSrc: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.src = imageSrc;

    img.onload = () => resolve(true);
    img.onerror = () => reject();
  });

export function useImageSequenceLoader(images: string[] = [], load = true) {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadImages = useCallback(async () => {
    await Promise.all(
      images.map((imageSrc) => getImagePromise(imageSrc).finally())
    );
    setIsLoaded(true);
  }, [images]);

  useEffect(() => {
    if (load) {
      loadImages();
    }
  }, [load]);

  return { isLoaded };
}
