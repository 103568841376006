import React, {
  useState,
  FunctionComponent,
  ReactNode,
  createContext,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import CustomToastMessages from '@components/Unstructured/CustomToastMessages';

interface IContextState {
  isOnline: boolean;
}

interface IContextActions {
  // setIsVariant: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IContextValue {
  state: IContextState | undefined;
  actions: IContextActions;
}

const MainContext = createContext<IContextValue | undefined>(undefined);
const { Provider: ContextElement } = MainContext;

interface IProviderProps {
  children: ReactNode;
}

const Provider: FunctionComponent<IProviderProps> = (props) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const intl = useIntl();

  const connectionRestore = () => {
    console.log('Internet connection restored');
    setIsOnline(true);
    toast(<CustomToastMessages type={'connectionRestored'} />, {
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // Функція, яка буде викликана, коли з'єднання втрачено
  const connectionLost = () => {
    console.log('Internet connection lost');
    toast(<CustomToastMessages type={'connectionClosed'} />, {
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', connectionRestore);
    window.addEventListener('offline', connectionLost);

    return () => {
      window.removeEventListener('online', connectionRestore);
      window.removeEventListener('offline', connectionLost);
    };
  }, []);

  const state: IContextState = { isOnline };
  const actions: IContextActions = {};

  return (
    <ContextElement value={{ state, actions }}>{props.children}</ContextElement>
  );
};

const MainProvider = connect(null, null)(Provider);

export { MainProvider, MainContext };
