import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { missionActionTypes } from './constants';
import { IMissions } from '@common/types/missions.types';

interface MissionState {
  isLoading: boolean;
  error: null | any;
  missions: IMissions | null;
}

const initialState: MissionState = {
  isLoading: false,
  error: null,
  missions: null,
};

// @ts-ignore
const missionReducer: Reducer<Map<string, any>, missionActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case missionActionTypes.GET_MISSIONS:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case missionActionTypes.GET_MISSIONS_SUCCESS:
      return state.merge({
        isLoading: false,
        missions: payload,
      });

    case missionActionTypes.GET_MISSIONS_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case missionActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });

    default:
      return state;
  }
};

export default missionReducer;
