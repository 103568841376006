import {
  getValueFromLocalStorage,
  setValueToLocalStorage,
  removeValueFromLocalStorage,
} from '@services/tokenStorageService';

const STATUS_BAR_CACHE_KEY = 'status_bar_cache';
const CACHE_DURATION = 60 * 1000; // 1 минута

export class StatusBarCacheManager {
  static isValid(): boolean {
    const cached = getValueFromLocalStorage(STATUS_BAR_CACHE_KEY);
    if (!cached) return false;

    const cache = JSON.parse(cached);
    const now = Date.now();
    return now - cache.timestamp < CACHE_DURATION;
  }

  static get(): any {
    const cached = getValueFromLocalStorage(STATUS_BAR_CACHE_KEY);
    return cached ? JSON.parse(cached).data : null;
  }

  static set(data: any): void {
    const cache = {
      data,
      timestamp: Date.now(),
    };

    setValueToLocalStorage(STATUS_BAR_CACHE_KEY, JSON.stringify(cache));
  }

  static clear(): void {
    removeValueFromLocalStorage(STATUS_BAR_CACHE_KEY);
  }
}
