import axios from 'axios';
import { IS_DEMO_ENABLED } from '@services/config';

export enum GameErrorType {
  SOCKET_ERROR = 'Socket Connection Error',
  GAME_CREATION_ERROR = 'Game Creation Error',
  OPPONENT_SEARCH_TIMEOUT = 'Opponent Search Timeout',
  ROOM_CLOSE_ERROR = 'Room Close Error',
  EMAIL_AUTH_ERROR = 'Email Authentication Error',
  TELEGRAM_AUTH_ERROR = 'Telegram Authentication Error',
  RENDER_ERROR = 'Component Render Error',
  GAME_NOT_FOUND = 'Game Not Found',
}

interface ErrorNotificationPayload {
  errorType: GameErrorType;
  userId?: string | number;
  gameId?: string | number;
  gameType?: string;
  rate?: number;
  currency?: string;
  additionalInfo?: string;
  componentName?: string;
  errorStack?: string;
}

const WEBHOOK_URLS = {
  demo: 'https://discord.com/api/webhooks/1306538107553976330/Geed2blwWSPT1B4e6EVCqjIop1CPUKG7hG8GqgeNJlo9a-88lh9nADXwGOkuiO3l8FTB',
  prod: 'https://discord.com/api/webhooks/1303492284817604639/eXOaKYkJyIuULBQpDAuq1yv4HqyCEJTezDUFuo5pBLhzFs2H-8yQNI2sNNzRM2x499iF',
};

export class DiscordNotificationService {
  private webhookUrl: string;

  constructor() {
    this.webhookUrl = IS_DEMO_ENABLED ? WEBHOOK_URLS.demo : WEBHOOK_URLS.prod;
  }

  async sendErrorNotification({
    errorType,
    userId,
    gameId,
    gameType,
    rate,
    currency,
    additionalInfo,
    componentName,
    errorStack,
  }: ErrorNotificationPayload): Promise<boolean> {
    const embed = {
      title: `🚨 Game Error: ${errorType}`,
      color: 0xff0000,
      fields: [
        {
          name: 'Timestamp',
          value: new Date().toISOString(),
          inline: true,
        },
        {
          name: 'Environment',
          value: IS_DEMO_ENABLED ? 'Demo' : 'Production',
          inline: true,
        },
      ],
      footer: {
        text: 'Game Platform Error Monitoring',
      },
    };

    if (userId) {
      embed.fields.push({
        name: 'User ID',
        value: userId.toString(),
        inline: true,
      });
    }

    if (gameId) {
      embed.fields.push({
        name: 'Game ID',
        value: gameId.toString(),
        inline: true,
      });
    }

    if (gameType) {
      embed.fields.push({
        name: 'Game Type',
        value: gameType,
        inline: true,
      });
    }

    if (rate && currency) {
      embed.fields.push({
        name: 'Bet Amount',
        value: `${rate} ${currency}`,
        inline: true,
      });
    }

    if (additionalInfo) {
      embed.fields.push({
        name: 'Additional Info',
        value: additionalInfo,
        inline: false,
      });
    }

    if (errorStack) {
      embed.fields.push({
        name: 'Error Stack',
        value: errorStack.slice(0, 1024), // Discord limit
        inline: false,
      });
    }

    if (componentName) {
      embed.fields.push({
        name: 'Component',
        value: componentName,
        inline: true,
      });
    }

    try {
      const response = await axios.post(this.webhookUrl, {
        embeds: [embed],
      });

      return response.status === 204;
    } catch (error) {
      console.error('Failed to send Discord notification:', error);
      return false;
    }
  }
}

export const discordNotification = new DiscordNotificationService();
