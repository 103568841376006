import get from 'lodash/get';
import { bufferTimeForRefreshToken } from '@api/constants';

const REFRESH_TOKEN_PATH = 'refresh_token';
const ACCESS_TOKEN_PATH = 'access_token';
const ACCESS_EXPIRATION_PATH = 'access_expiration';

const authStateService = () => {
  const setToken = async (tokenObj) => {
    const accessToken = get(tokenObj, 'token', '');
    const refreshToken = get(tokenObj, 'refresh_token', '');
    const accessExpiration = get(tokenObj, 'accessExpiration', '');

    await localStorage.setItem(ACCESS_TOKEN_PATH, JSON.stringify(accessToken));
    await localStorage.setItem(
      REFRESH_TOKEN_PATH,
      JSON.stringify(refreshToken)
    );
    await localStorage.setItem(
      ACCESS_EXPIRATION_PATH,
      JSON.stringify(accessExpiration)
    );
  };

  const getAccessToken = async () =>
    JSON.parse(await localStorage.getItem(ACCESS_TOKEN_PATH));

  const getAccessExpiration = async () =>
    JSON.parse(await localStorage.getItem(ACCESS_EXPIRATION_PATH));

  const getRefreshToken = async () =>
    JSON.parse(await localStorage.getItem(REFRESH_TOKEN_PATH));

  const clearToken = async () => {
    await localStorage.removeItem(ACCESS_TOKEN_PATH);
    await localStorage.removeItem(REFRESH_TOKEN_PATH);
    await localStorage.removeItem(ACCESS_EXPIRATION_PATH);
  };

  const checkIsAccessTokenExpired = async () => {
    const accessExpiration = await getAccessExpiration();

    if (!Number.isInteger(accessExpiration)) {
      return true;
    }

    const refreshTime = Date.now() + bufferTimeForRefreshToken;

    return refreshTime >= accessExpiration;
  };

  return {
    setToken,
    getAccessToken,
    getRefreshToken,
    getAccessExpiration,
    clearToken,
    checkIsAccessTokenExpired,
  };
};

export default authStateService;
