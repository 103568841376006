import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { MainProvider } from '@context/MainContext';
import './shared/styles/main.css';
import TranslateProvider from './shared/localization';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import App from './App';

import ReactGA from 'react-ga4';
import { IS_GA_ENABLED, IS_GTM_ENABLED } from '@services/config';
import { gaUserSource } from '@utils/helpers/helpers';

ReactGA.initialize(IS_GA_ENABLED ? 'G-HLVTZHNLJW' : 'null', {
  gaOptions: {
    user_source: gaUserSource(),
  },
});
TagManager.initialize({
  gtmId: IS_GTM_ENABLED ? 'GTM-PKPTTH59' : 'null',
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <TranslateProvider>
        <MainProvider>
          <App />
        </MainProvider>
      </TranslateProvider>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
