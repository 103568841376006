export interface IDuel {
  currencyCode: string;
  earnedWintickets: number | null;
  gameName: string;
  gameTime: string;
  opponent: string;
  opponentTotalAll: number;
  rate: number;
  result: RoundStatusType;
  totalAll: number;
}

export interface IDuels {
  countItem: number;
  entities: IDuel[];
  lastPage: number;
  page: number;
}

export interface IGame {
  availableRates: number[];
  description: string;
  id: number;
  name: string;
  opened?: boolean;
  gamesToOpen?: number;
  requiredGamesCount?: number;
}

export interface IEventGALobby {
  returnedGame?: string | boolean;
  startSearchGame?: string | boolean;
  depositShown?: string | boolean;
}

export interface IBalance {
  availableBalance: string;
  availableBalanceUSD: string;
  balance: string;
  balanceUSD: string;
  currencyId: string;
  holdBalance: string;
  nfts: {
    erc721: [];
    erc1155: [];
  };
}

export type WtJackpotsType = Record<string, Record<number, string[]>>;

export interface IGetMainResponseModel {
  agreementAccepted: number[];
  balance: Record<string, IBalance>;
  bonusReceived: boolean;
  currencies: {
    availableRates: number[];
    currencyCode: string;
    innerCurrency: boolean;
  }[];
  game: IGame;
  image: string;
  nickName: string;
  numberDuels: number;
  userWaitingList: {
    id: number;
    gameName: string;
    rate: number;
  };
  winTicketForLoginReceived: boolean;
  winTicketForRegisterReceived: boolean;
  wintickets: number;
  wtJackpots: WtJackpotsType;
}

export type WtJackpotType = Record<
  string,
  Record<
    number,
    {
      earnedWT: string;
      winsInRow: number;
    }
  >
>;

export interface IGetStatusBarModel {
  balance: {
    balance: string;
    currency: string;
  }[];
  jackpot: null | any;
  lastPlayer: {
    isWin: boolean;
    nickName: string;
    wonUSDTByGame: number;
    wonUSDTByJackpot: number;
  };
  onlineUsers: number;
  wintickets: number;
  wtJackpot: WtJackpotType;
}

export interface ILastPlayer {
  name: string;
  value: number;
}

export interface IRequestPlayGameModel {
  gameId: string | number;
  rate: string | number;
  intl?: any;
  currencyCode: string;
}

export interface IRequestDeleteGameModel {
  gameId: string | number;
  intl?: any;
}

export interface IRequestCloseRoomModel {
  roomId: string | number;
  intl?: any;
}

export interface IRequestGameReadyModel {
  statisticRoundId: string | number;
  intl?: any;
}

export interface IRequestTimerExpiredModel {
  timerExpired: string | number | null;
  intl?: any;
}

export interface IRequestRoundCloseModel {
  roundClose: string | number;
  intl?: any;
}

export interface IRequestRoundValueModel {
  statisticRoundId: string | number;
  value: string | number;
  intl?: any;
}

export interface IRequestRoundResultModel {
  roundResult: string | number;
  intl?: any;
}

export interface IRequestGameResultModel {
  gameRessult: string | number;
  intl?: any;
}

export enum ERoundStatus {
  Won = 'Won',
  Lost = 'Lost',
  Draw = 'Draw',
}

export enum EAnimal {
  Bear = 'bear',
  Hamster = 'hamster',
  Bull = 'bull',
}

export enum EPentagram {
  Earth = 'earth',
  Air = 'air',
  Ether = 'ether',
  Fire = 'fire',
  Water = 'water',
}

export enum EPenalty {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum EPenaltyRole {
  Goalkeeper = 'goalkeeper',
  Striker = 'striker',
}

export enum EGames {
  BearHamsterBull = 'BEAR-HAMSTER-BULL',
  FiveInAll = 'FIVE-IN-ALL',
  TotalZero = 'TOTAL-ZERO',
  TwentyOne = 'TWENTY-ONE',
  Pentagram = 'PENTAGRAM',
  Penalty = 'PENALTY',
}

export type RoundStatusType = 'Lost' | 'Won' | 'Draw' | null;
export type AnimalType = 'bear' | 'hamster' | 'bull' | null;
export type FiveInAllType = (string | number)[] | string | null;
export type TotalZeroType = string | null;
export type TwentyOneType = string | null;
export type PentagramType = 'earth' | 'air' | 'ether' | 'fire' | 'water' | null;
export type PenaltyType = 'left' | 'center' | 'right' | 'Won' | 'Lost' | null;
export type PenaltyRoleType = 'goalkeeper' | 'striker' | null;

export type ValuesType =
  | AnimalType
  | FiveInAllType
  | TotalZeroType
  | TwentyOneType
  | PentagramType
  | PenaltyType;

export interface IPenaltyRound {
  roundNumber: number;
  roundNumberPenalty: number;
  playerPenaltyRole: PenaltyRoleType;
  roundResult: RoundStatusType;
  valueCompetitor: ValuesType;
  valuePlayer: ValuesType;
}

export interface IStatisticRound {
  competitorId: number;
  competitorNickName: string;
  competitorWinNumber: number;
  competitorWinRate: number;
  currencyCode: string;
  dateStartRound: string | null;
  gameName: string;
  gameResult: string | null;
  imageCompetitor: string | null;
  imagePlayer: string | null;
  isReady: boolean | null;
  keys: string[];
  loseNumber: number;
  penaltyRoundStatistic: IPenaltyRound[];
  playerId: number;
  playerNickName: string;
  playerPenaltyRole: PenaltyRoleType;
  playerWinRate: number;
  rate: number;
  roomId: number;
  roundNumber: number;
  roundNumberPenalty: number;
  roundResult: string | null;
  selectValuePlayer: ValuesType;
  statisticRoundId: number;
  timerExpired: boolean;
  winNumber: number;
}

export interface IRoundResult {
  isReady: boolean;
  playerId: number;
  roundResult: RoundStatusType;
  selectValueCompetitor: ValuesType;
  selectValuePlayer: ValuesType;
  statisticRoundId: number;
  timerExpired: boolean;
}

export interface IRoundDetails {
  roundResult: RoundStatusType;
  valueCompetitor: ValuesType;
  valuePlayer: ValuesType;
}

export interface IGameResult {
  competitorWinNumber: number;
  gameResult: RoundStatusType;
  loseNumber: number;
  playerId: number;
  round: Record<string, IRoundDetails>;
  winNumber: number;
}

export interface ISocketGameModel {
  statisticRound: IStatisticRound | null;
  roundResult: IRoundResult | null;
  gameResult: IGameResult | null;
}

export interface IAgreementRules {
  gameId: string | number;
  intl?: any;
}

export type WebSocketConnectionStatus =
  | 'OPEN'
  | 'CLOSED'
  | 'CONNECTING'
  | 'ERROR'
  | null;
