import React, { ComponentType, lazy, useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import 'react-toastify/dist/ReactToastify.css';
import { IS_MAINTENANCE } from '@services/config';
import MaintenanceScreen from '@views/MaintenanceScreen';

const DashboardScreen = lazy(() => import('@views/DashboardScreen'));
const FiveInAllScreen = lazy(() => import('@views/FiveInAllScreen'));
const LandingScreen = lazy(() => import('@views/LandingScreen'));
const LeaderboardScreen = lazy(() => import('@views/LeaderboardScreen'));
const LeaderboardComingScreen = lazy(
  () => import('@views/LeaderboardComingScreen')
);
const LoginScreen = lazy(() => import('@views/LoginScreen'));
const MainScreen = lazy(() => import('@views/MainScreen'));
const MarketComingScreen = lazy(() => import('@views/MarketComingScreen'));
const MissionsScreen = lazy(() => import('@views/MissionsScreen'));
const TournamentComingScreen = lazy(
  () => import('@views/TournamentComingScreen')
);
const PageNotFoundScreen = lazy(() => import('@views/PageNotFoundScreen'));
const PenaltyScreen = lazy(() => import('@views/PenaltyScreen'));
const PentagramScreen = lazy(() => import('@views/PentagramScreen'));
const PreloadSpinnerScreen = lazy(() => import('@views/PreloadSpinnerScreen'));
const ProfileScreen = lazy(() => import('@views/ProfileScreen'));
const ResetPasswordScreen = lazy(() => import('@views/ResetPasswordScreen'));
const ResultScreen = lazy(() => import('@views/ResultScreen'));
const SpinnerScreen = lazy(() => import('@views/SpinnerScreen'));
const TotalZeroScreen = lazy(() => import('@views/TotalZeroScreen'));
const TransactionScreen = lazy(() => import('@views/TransactionScreen'));
const TwentyOneScreen = lazy(() => import('@views/TwentyOneScreen'));

import { IGame, EGames, IEventGALobby } from '@common/types/dashboard.types';
import CoinLoader from '@components/Attoms/CoinLoader';
import { handleCheckToken, postLoginTelegram } from '@redux/auth/actions';
import {
  selectIsAuthorized,
  selectIsCheckingAuthorization,
} from '@redux/auth/selectors';
import {
  setRewardsForSignUpAction,
  postGaStatistics,
} from '@redux/dashboard/actions';
import {
  selectSelectedGame,
  selectGaStatistics,
} from '@redux/dashboard/selectors';
import { useVisibilityChange } from '@services/hookHelpers';
import { useMusic } from '@services/music';
import {
  getValueFromLocalStorage,
  setValueToLocalStorage,
} from '@services/tokenStorageService';
import { Wrapper, StyledToastContainer } from '@shared/styles/My.styled';

import { sound } from '@redux/profile/selectors';

import ReactGA from 'react-ga4';
import { GA_CATEGORIES } from '@services/config';
import { gaTelegram, gaUserSource } from '@utils/helpers/helpers';

const PROTECTED_ROUTES = [
  '/dashboard',
  '/five-in-all',
  '/leaderboard',
  '/leaderboard-coming',
  '/market-coming',
  '/missions',
  '/tournament-coming',
  '/penalty',
  '/pentagram',
  '/preload-game',
  '/profile',
  '/result',
  '/spinner',
  '/total-zero',
  '/transaction',
  '/twenty-one',
];

const GLOBAL_SOUND_ROUTES = [
  '/dashboard',
  '/leaderboard',
  '/leaderboard-coming',
  '/market-coming',
  '/missions',
  '/tournament-coming',
  '/preload-game',
  '/profile',
  '/transaction',
];

const existingRoutes = [
  '/',
  '/authorization',
  '/dashboard',
  '/five-in-all',
  '/leaderboard',
  '/leaderboard-coming',
  '/main',
  '/market-coming',
  '/missions',
  '/tournament-coming',
  '/penalty',
  '/pentagram',
  '/preload-game',
  '/profile',
  '/reset',
  '/result',
  '/spinner',
  '/total-zero',
  '/transaction',
  '/twenty-one',
];

// @ts-ignore
const context = new (window.AudioContext || window.webkitAudioContext)();
// @ts-ignore
// eslint-disable-next-line no-undef
unmute(context);

interface AppProps {
  isAuthorized: boolean;
  isCheckingAuthorization: boolean;
  gaStatistics: any;
  selectedGameData: IGame;
  checkToken: () => void;
  handleGaStatistics: (params: IEventGALobby, game: number) => Promise<void>;
  handleLoginUserTelegram: (params: any) => Promise<void>;
  handleRewardsForSignUp: (params: boolean) => void;
  soundData: boolean;
}

const App: ComponentType<AppProps> = ({
  isAuthorized,
  isCheckingAuthorization,
  gaStatistics,
  selectedGameData,
  checkToken,
  handleGaStatistics,
  handleLoginUserTelegram,
  handleRewardsForSignUp,
  soundData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [appInitialized, setAppInitialized] = useState(false);
  const music = useMusic(soundData, selectedGameData?.name as EGames);
  const { isVisible } = useVisibilityChange();

  // @ts-ignore
  const telegram = window.Telegram.WebApp;

  useEffect(() => {
    telegram.ready();

    ReactGA.event('all_visit' + gaTelegram(), {
      category: GA_CATEGORIES.USER,
      user_source: gaUserSource(),
    });

    const returningUser = getValueFromLocalStorage('welcomeTo');

    if (returningUser !== 'true') {
      ReactGA.event('first_visit' + gaTelegram(), {
        category: GA_CATEGORIES.USER,
        user_source: gaUserSource(),
      });
    } else {
      ReactGA.event('second_visit' + gaTelegram(), {
        category: GA_CATEGORIES.USER,
        user_source: gaUserSource(),
      });
    }
  }, []);

  useEffect(() => {
    if (isCheckingAuthorization) {
      const isTelegram = getValueFromLocalStorage('isTelegram');
      const params = new URLSearchParams(window.location.search);
      const newUser = params.get('new_user') || '0';

      if (newUser === '1') {
        handleRewardsForSignUp(true);
        setValueToLocalStorage('welcomeTo', true);
      }

      const tokenObj = {
        token: params.get('token') || '',
      };

      if (tokenObj.token) {
        setValueToLocalStorage('isTelegram', true);
        handleLoginUserTelegram(tokenObj);
      } else {
        !isTelegram && setValueToLocalStorage('isTelegram', false);
      }
      setTimeout(() => {
        checkToken();
      }, 1000);
    } else {
      setAppInitialized(true);
    }
  }, [window.location, isCheckingAuthorization]);

  useEffect(() => {
    if (gaStatistics && gaStatistics.data && gaStatistics.data.deposit) {
      ReactGA.event('deposit_success', {
        category: GA_CATEGORIES.FINANCIAL,
        user_source: gaUserSource(),
      });

      handleGaStatistics({ depositShown: 'true' }, 1);
    }
  }, [gaStatistics]);

  useEffect(() => {
    if (!isVisible) {
      if (isIOS && isMobile) {
        music.stop();
      }
    }

    if (GLOBAL_SOUND_ROUTES.includes(location.pathname)) {
      music.play(selectedGameData?.name as EGames);
    } else {
      music.stop();
    }
  }, [location.pathname, selectedGameData?.name, isVisible]);

  useEffect(() => {
    if (!existingRoutes.includes(location.pathname)) {
      return;
    }

    if (appInitialized) {
      if (
        isAuthorized &&
        (PROTECTED_ROUTES.includes(location.pathname) ||
          location.pathname === '/main')
      ) {
        // Убрана логика обновления refresh token
        ReactGA.event('returned_lobby_log' + gaTelegram(), {
          category: GA_CATEGORIES.USER,
          user_source: gaUserSource(),
        });
      } else if (isAuthorized) {
        navigate('/dashboard');
      } else if (
        !isAuthorized &&
        PROTECTED_ROUTES.includes(location.pathname)
      ) {
        navigate('/');
      }
    }
  }, [isAuthorized, location.pathname, navigate, appInitialized]);

  if (!appInitialized) {
    return <CoinLoader visible={true} />;
  }

  if (IS_MAINTENANCE) {
    return <MaintenanceScreen />;
  }

  return (
    <Wrapper>
      <StyledToastContainer position="top-center" />
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense>
              <MainScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/authorization"
          element={
            <React.Suspense>
              <LoginScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/dashboard"
          element={
            <React.Suspense>
              <DashboardScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/five-in-all"
          element={
            <React.Suspense>
              <FiveInAllScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/leaderboard"
          element={
            <React.Suspense>
              <LeaderboardScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/leaderboard-coming"
          element={
            <React.Suspense>
              <LeaderboardComingScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/main"
          element={
            <React.Suspense>
              <LandingScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/market-coming"
          element={
            <React.Suspense>
              <MarketComingScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/missions"
          element={
            <React.Suspense>
              <MissionsScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/tournament-coming"
          element={
            <React.Suspense>
              <TournamentComingScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/penalty"
          element={
            <React.Suspense>
              <PenaltyScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/pentagram"
          element={
            <React.Suspense>
              <PentagramScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/preload-game"
          element={
            <React.Suspense>
              <PreloadSpinnerScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <React.Suspense>
              <ProfileScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/reset"
          element={
            <React.Suspense>
              <ResetPasswordScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/result"
          element={
            <React.Suspense>
              <ResultScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/spinner"
          element={
            <React.Suspense>
              <SpinnerScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/total-zero"
          element={
            <React.Suspense>
              <TotalZeroScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/transaction"
          element={
            <React.Suspense>
              <TransactionScreen />
            </React.Suspense>
          }
        />
        <Route
          path="/twenty-one"
          element={
            <React.Suspense>
              <TwentyOneScreen />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense>
              <PageNotFoundScreen />
            </React.Suspense>
          }
        />
      </Routes>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  checkToken: handleCheckToken,
  handleGaStatistics: postGaStatistics,
  handleLoginUserTelegram: postLoginTelegram,
  handleRewardsForSignUp: setRewardsForSignUpAction,
};

const mapStateToProps = createStructuredSelector({
  isAuthorized: selectIsAuthorized,
  isCheckingAuthorization: selectIsCheckingAuthorization,
  gaStatistics: selectGaStatistics,
  selectedGameData: selectSelectedGame,
  soundData: sound,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
