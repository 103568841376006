import React from 'react';

import { useImageSequenceLoader } from '@shared/utils/hooks/imageSequenceLoader';

import { Coin, DEFAULT_IMAGES, RETINA_IMAGES } from './My.styled';

const CoinAnimation: React.FC = () => {
  const isRetina = window.devicePixelRatio > 1;

  const { isLoaded: defaultLoaded } = useImageSequenceLoader(
    DEFAULT_IMAGES,
    !isRetina
  );

  const { isLoaded: retinaLoaded } = useImageSequenceLoader(
    RETINA_IMAGES,
    isRetina
  );

  const loaded = isRetina ? retinaLoaded : defaultLoaded;

  if (!loaded) {
    return null;
  }

  return <Coin />;
};

export default CoinAnimation;
