import axios, { Axios, AxiosError, AxiosRequestConfig } from 'axios';
import { TimeCache } from '@services/timeCache';
import QueryString from 'qs';

export const CACHED_METHODS = new Set(['GET']);
export const RESET_METHODS = new Set(['POST', 'PUT', 'PATCH', 'DELETE']);
export const CACHE_CANCEL_CODE = 'CACHE_CANCEL_CODE';

const generateRequestKey = (config: AxiosRequestConfig) =>
  config.url + QueryString.stringify(config.params);

export default function setupCacheInterceptor(axiosInstance: Axios) {
  const apiCache = new TimeCache();

  axiosInstance.interceptors.request.use((config) => {
    const { method, url, clientCache } = config;

    if (clientCache !== true) {
      return config;
    }

    if (url && method) {
      const normalizedMethod = method.toUpperCase();
      const key = generateRequestKey(config);

      if (CACHED_METHODS.has(normalizedMethod)) {
        const record = apiCache.readCache(key);

        if (record) {
          throw new axios.AxiosError(
            'Request cancelled because of cache',
            CACHE_CANCEL_CODE,
            config
          );
        }
      } else if (RESET_METHODS.has(normalizedMethod)) {
        apiCache.resetCache(key);
      }
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (value) => {
      const { method, url } = value.config;

      if (url && CACHED_METHODS.has(method?.toUpperCase() as string)) {
        const key = generateRequestKey(value.config);

        apiCache.writeCache(key, value);
      }

      return value;
    },
    (error: AxiosError) => {
      const { code, config } = error;

      if (config && code === CACHE_CANCEL_CODE) {
        const { url } = config;

        if (!url) {
          // eslint-disable-next-line no-console
          console.warn('Client HTTP Cache :: No request URL');
          throw error;
        }

        const key = generateRequestKey(config);
        const record = apiCache.readCache(key);

        if (!record) {
          // eslint-disable-next-line no-console
          console.warn(
            'Client HTTP Cache :: Request was cancelled because of cache but no cached data found'
          );
          throw error;
        }

        return record?.data;
      }

      throw error;
    }
  );

  return { axiosInstance, apiCache };
}
