import { createSelector } from 'reselect';
import { IGetProfileResponseModel } from '@common/types/profile.types';

export type WalletsByCurrency = Record<
  string,
  Pick<IGetProfileResponseModel, 'wallets'>['wallets'][number]
>;

const selectProfile = (state: any) => state.get('profile');

export const selectIsLoading = createSelector(selectProfile, (substate) =>
  substate.get('isLoading')
);

export const selectProfileInfo = createSelector(selectProfile, (substate) =>
  substate.get('profileInfo')
);
export const selectSort = createSelector(selectProfile, (substate) =>
  substate.get('selectedSortListOfDuels')
);

export const sound = createSelector(selectProfile, (substate) =>
  substate.get('sound')
);

export const selectWalletsByCurrency = createSelector(
  selectProfile,
  (substate) => {
    const profileInfo = substate.get('profileInfo') as IGetProfileResponseModel;

    if (!profileInfo) {
      return {};
    }

    return profileInfo.wallets.reduce((acc, curr) => {
      acc[curr.currencyCode] = curr;
      return acc;
    }, {} as WalletsByCurrency);
  }
);
