import Theme from './Theme';
export const device = {
  desktopsLarge: `(min-width: ${'1660px'})`,
  desktops: `(min-width: ${Theme.mediaQueries.width.desktops.min})`,
  laptops: `(min-width: ${Theme.mediaQueries.width.laptops.min}) and (max-width: ${Theme.mediaQueries.width.laptops.max})`,
  tablets: `(min-width: ${Theme.mediaQueries.width.tablets.min}) and (max-width: ${Theme.mediaQueries.width.tablets.max})`,
  mobileLarge: `(max-width: ${Theme.mediaQueries.width.largeMobile.max})`,
  mobileSmall: `(min-width: ${Theme.mediaQueries.width.smallMobile.min}) and (max-width: ${Theme.mediaQueries.width.smallMobile.max})`,
  mobileLargeLandscape: `(max-width: ${Theme.mediaQueries.width.largeMobile.max}) and (orientation:landscape)`,
};
